.active-session-manager {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}

.active-session-manager .spinner-border {
    color: #cecece !important;
    margin-right: 12px;
}

.active-session-manager .btn {
    color: #FFFFFF;
}

.active-session-manager .session-time {
    color: #cecece;
}

.active-session-manager-popup {
    width: 260px;
}

.active-session-manager-popup .modal-title {
    text-transform: uppercase;
}

.active-session-manager-popup .modal-body>div {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 16px;
}

.active-session-manager-popup .session-time-picker {
    width: 120px;
}

.active-session-manager-popup .session-time-picker input {
    text-align: center;
    font-size: 15px;
}
